import React, { useContext, useEffect, useState }  from 'react'
import './contact.scss'
import { Fetcher } from '../../helpers/api/Fetcher';
import { language } from '../../App';
import H1 from '../../components/Fonts/H1/H1';
import H3 from '../../components/Fonts/H3/H3';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Contact = () => {
  const { currentLanguage } = useContext(language)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({})

  useEffect(() => {
    const fecthApi = async () => {
      const URL = currentLanguage==='hu' ? 'https://beta.haromstudio.hu/scriptedprod/wp-json/wp/v2/pages/104' : 'https://beta.haromstudio.hu/scriptedprod/wp-json/wp/v2/pages/110';
      const res = await Fetcher({ URL, setIsLoading })
      setData(res)
      
      setIsLoading(false)
    }
    
    fecthApi()
  }, [currentLanguage])


  if (!isLoading && data.acf) {
    return (
      <div className='contact'>
          <Container>
            <Row className='mt-4'>
              <Col xs={12}>
                <div className='block block-site-title mt-2'>
                  <H1 text={data.title.rendered} />
                  <p dangerouslySetInnerHTML={{ __html: data.acf.cim }}></p>
                </div>
              </Col>
            </Row>
          </Container>

          <div className='gap'></div>

          <Container>
            <Row className='mt-4'>
              {/* <Col xs={12}>
                <iframe src="https://snazzymaps.com/embed/439703" width="100%" height="600px" style={{border: 'none'}} title='map' className='map'></iframe>
              </Col> */}

              {/* <Col xs={12}>
                <p className='my-4 ms-1 ms-md-3' dangerouslySetInnerHTML={{ __html: data.acf.jelentkezes_szoveg }}></p>
              </Col> */}
            </Row>
          </Container>
      </div>
    )
  } else {
    return (
      <div className='body'></div>
    )
  }
}

export default Contact