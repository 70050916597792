import React from 'react'
import './H2.scss'

const H2 = ({text, className=''}) => {
    const classes = `h2 ${className}`;
  return (
    <h2 className={classes}>{text}</h2>
  )
}

export default H2