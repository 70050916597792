import React, { useContext, useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import H1 from '../../components/Fonts/H1/H1'
import { Col, Container, Row } from 'react-bootstrap'
import { Fetcher } from '../../helpers/api/Fetcher';
import './detailDeveloped.scss'
import { language } from '../../App';

const DetailDeveloped = () => {
    const { currentLanguage } = useContext(language)
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState({})


    useEffect( () => {
        const fetchApi = async () => {
            let URL;
            if (data.translations) {
                URL =`https://beta.haromstudio.hu/scriptedprod/wp-json/wp/v2/sajat-fejlesztes/${data.translations[currentLanguage]}?&_embed&acf_format=standard`;
            } else {
                URL = `https://beta.haromstudio.hu/scriptedprod/wp-json/wp/v2/sajat-fejlesztes/${id}?&_embed&acf_format=standard`;
            }
            const res = await Fetcher({URL, setIsLoading})
            setData(res)
            setIsLoading(false)
        }
        
        fetchApi()
    }, [currentLanguage])

    if (data.acf && !isLoading) {
        return (
            <div className='body'>
                <Container>
                    <Row className='mt-4'>
                    <Col xs={12}>
                        <div className='block block-site-title mt-2'>
                        <H1 text={data.title.rendered} />
                        </div>
                    </Col>
                    </Row>
                </Container>

                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className='images' >
                                {data.acf.kepek.map(({kep}) => {
                                    return <img className='image' src={kep} alt="img" />
                                })}
                            </div>
                        </Col>
                    </Row>
                </Container>
                
            </div>
        )
    }

    return (
        <div className='body'></div>
      )
}

export default DetailDeveloped