import React from 'react'
import './imageGrid.scss'
import { Link } from 'react-router-dom'
import H2 from '../Fonts/H2/H2'

const ImageGrid = ({data}) => {
    return (
        <div className='image-grid'>
            {data.map(prod => {
            return  <Link to={`/${prod.type === "sajat-fejlesztes" ? "sajat-fejlesztesek" : "produkciok"}/${prod.id}`} key={prod.id}>
                        <div className='grid-poster'>
                            <div className='poster' alt='poster' style={{backgroundImage: `url(${prod._embedded["wp:featuredmedia"][0].source_url})`}} />
                            <H2 text={prod.title.rendered}/>
                        </div>
                    </Link>
            })}
        </div>
    )
  
}

export default ImageGrid