import React from 'react'
import './slider.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';
import { Link } from 'react-router-dom';

const Slider = ({data}) => {
  return (
    <Swiper
      spaceBetween={20}
      modules={[Autoplay]}
      autoplay={{ disableOnInteraction : false}}
      disableoninteraction="false"
      loop
      breakpoints={{
        0: {
          slidesPerView: 1,
        },
        991: {
          slidesPerView: 2,
        }
      }}
    >
      {data.map((prod) => {
        return <SwiperSlide key={prod.id} style={{backgroundImage: `url(${prod._embedded['wp:featuredmedia'][0].source_url})`}}><Link to={`/produkciok/${prod.id}`} /></SwiperSlide>
      })}
    </Swiper>
  )
}

export default Slider