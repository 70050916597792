import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom"
import { Fetcher } from '../../helpers/api/Fetcher';
import './detailProd.scss'
import VideoSlider from '../../components/VideoSlider/VideoSlider'
import P from '../../components/Fonts/P/P'
import H1 from '../../components/Fonts/H1/H1'
import H2 from '../../components/Fonts/H2/H2'
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Container, Row } from 'react-bootstrap'
import { language } from '../../App';
import Vimeo from '@u-wave/react-vimeo';

const DetailProd = () => {
    const { currentLanguage } = useContext(language)
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState({})


    useEffect( () => {
        const fetchApi = async () => {
            let URL;
            if (data.translations) {
                URL =`https://beta.haromstudio.hu/scriptedprod/wp-json/wp/v2/produkcio/${data.translations[currentLanguage]}?&_embed`;
            } else {
                URL = `https://beta.haromstudio.hu/scriptedprod/wp-json/wp/v2/produkcio/${id}?&_embed`;
            }
            const res = await Fetcher({URL, setIsLoading})
            setData(res)
            setIsLoading(false)
        }
        
        fetchApi()
    }, [currentLanguage])

    if (!isLoading && data) {
        return (
            <div className='production-details body'>
                <Container>
                    <Row className='mt-4'>
                    <Col xs={12}>
                        <div className='block block-site-title mt-2'>
                        <H1 text={data.title.rendered} />
                        </div>
                    </Col>
                    </Row>
                </Container>

                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className='poster-grid'>
                                <div className='poster' style={{backgroundImage: `url(${data._embedded['wp:featuredmedia'][0].source_url})`}}/>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row className='mt-4'>
                        <Col className='flex-column'>
                            <div className='block-title'>
                                <H2 text={currentLanguage === 'hu' ? 'sztori' : "story"} />
                            </div>
                            <div className='block p-4'>
                                <div className='description my-4' dangerouslySetInnerHTML={{ __html: data.acf.sztori }}></div>
                            </div>
                        </Col>
                        {data.acf?.stab &&
                        <Col xs={12} lg={6} className='flex-column mt-4 mt-md-0'>
                            <div className='block-title'>
                                <H2 text={currentLanguage === 'hu' ? 'stáb' : 'staff'} />
                            </div>
                            <div className='block p-4'>
                                <div className='description my-4'>
                                    <ul>
                                    {data.acf.stab && data.acf?.stab.map(tag => {
                                        return tag.link ? 
                                            <li className='d-flex justify-content-between mb-1' key={id}>
                                                <strong>{tag.beosztas}</strong>
                                                <Button variant='link' href={`/csapat/${tag.csapattag_link}`}>
                                                    <P text={tag.csapattag} />
                                                    <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                                                </Button>
                                            </li> :
                                            <li className='d-flex justify-content-between' key={id}>
                                                <strong>{tag.beosztas}</strong>
                                                {tag.csapattag}
                                            </li>
                                    })}
                                    </ul>
                                    
                                    <div dangerouslySetInnerHTML={{ __html: data.acf.szereplok }}></div>
                                </div>
                            </div>
                        </Col>
                        }
                    </Row>
                </Container>
                
                {data.acf.promok &&
                <Container>
                    <Row className='mt-4'>
                        <Col xs={12}>
                            <div className='block-title'>
                                <H2 text={currentLanguage === 'hu' ? 'promók' : 'promos'} />
                            </div>
                            <div className='block p-4'>
                                {data.acf.promok && (data.acf.promok.length > 1 ? 
                                    <VideoSlider classNames='' videos={data.acf.promok}/> :
                                    <div className='video'><Vimeo video={data.acf.promok[0].vimeo_id}/></div>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
                }

                {data.acf.videok &&
                <Container>
                    <Row className='mt-4'>
                        <Col xs={12}>
                            <div className='block-title'>
                                <H2 text={currentLanguage === 'hu' ? 'videók' : 'videos'} />
                            </div>
                            <div className='block p-4'>
                                {data.acf.videok && (data.acf.videok.length > 1 ? 
                                    <VideoSlider videos={data.acf.videok}/> :
                                    <div className='video'><Vimeo video={data.acf.videok[0].vimeo_id}/></div>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
                }

                {data.acf.gomb_felirat !== '' && 
                    <Container>
                        <Row className='mt-4 justify-content-center'>
                            <Col className='d-flex justify-content-center'>
                                <Button variant='secondary' href={data.acf.gomb_url}>
                                    <P text={data.acf.gomb_felirat} />
                                    <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                }

                {data.acf.elismeresek &&
                <Container>
                    <Row className='my-5'>
                        <Col xs={12}>
                            <div className='block-title'>
                                <H2 text={currentLanguage === 'hu' ? 'elismerések' : 'acknowledgments'} />
                            </div>
                            <div className='block p-4'>
                                <div dangerouslySetInnerHTML={{ __html: data.acf.elismeresek }}></div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                }   
            </div>
        )
    } else {
        return (
            <div className='body'></div>
        )
    }
}

export default DetailProd