import React, { useContext, useEffect, useState } from 'react'
import ProjectCard from '../../components/ProjectCard/ProjectCard'
import { Fetcher } from '../../helpers/api/Fetcher';
import { language } from '../../App'
import './productions.scss'
import H1 from '../../components/Fonts/H1/H1';
import { Col, Container, Row } from 'react-bootstrap';


const Productions = () => {
  const { currentLanguage } = useContext(language)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])

  useEffect(() => {
    const fetchApi = async () => {
      const URL = `https://beta.haromstudio.hu/scriptedprod/wp-json/wp/v2/produkcio?_embed&lang=${currentLanguage}`;
      const res = await Fetcher({ URL, setIsLoading})
      setData(res)
    setIsLoading(false)

    }

    fetchApi()
  }, [currentLanguage])

  if (!isLoading) {
    return (
      <main className='productions'>
          <Container>
            <Row className='mt-4'>
              <Col xs={12}>
                <div className='block block-site-title mt-2'>
                  <H1 text={currentLanguage === 'hu' ? 'produkciók': 'productions'} />
                </div>
              </Col>
            </Row>
          </Container>

          <div className='project-cards'>
            <Container>
              <Row>
                  {data.map(production => {
                    return (
                      <Col xs={12} xl={6}>
                        <ProjectCard key={production.id} title={production.title.rendered} desc={`${production.acf.sztori.slice(0, 500)}...`} img={production._embedded['wp:featuredmedia'][0].source_url} url={`${window.location.pathname+'/'+production.id}`} />
                      </Col>
                  )
                  })}
              </Row>
            </Container>
          </div>
      </main>
    )
  } else {
    return (
      <div className='body'></div>
    )
  }
}

export default Productions