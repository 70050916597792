export const Fetcher = async ({ URL, setIsLoading }) => {
    setIsLoading(true)
    let res;

    await fetch(URL, {cache: 'no-store'})
    .then(res => res.json())
    .then(json => { res = json })

    return res
}

