import React, { useContext, useEffect, useState } from 'react'
import Vimeo from '@u-wave/react-vimeo';
import H1 from '../../components/Fonts/H1/H1';
import H2 from '../../components/Fonts/H2/H2';
import P from '../../components/Fonts/P/P';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './home.scss';
import Slider from '../../components/Slider/Slider';
import { Fetcher } from '../../helpers/api/Fetcher';
import { language } from '../../App';
import { Col, Container, Row } from 'react-bootstrap';
import { Button } from 'react-bootstrap';


const Home = () => {
  const { currentLanguage } = useContext(language)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({})
  const [prods, setProds] = useState([])
  // const [members, setMembers] = useState([])
  const [isProdLoading, setIsProdLoading] = useState(false)
  // const [isMemberLoading, setIsMemberLoading] = useState(false)
  // const [paused, setPaused] = useState(true)
  const companyName = 'Scripted \n Productions';

  useEffect(() => {
    const fecthApi = async () => {
      const URL = `https://beta.haromstudio.hu/scriptedprod/wp-json/wp/v2/pages/${currentLanguage==='hu'? 42 : 44}?_embed&acf_format=standard`;
      const res = await Fetcher({ URL, setIsLoading })
      setData(res)
      await fetchProds()
      // await fetchMembers()
      setIsLoading(false)
    }
    
    fecthApi()
  }, [currentLanguage])

  const fetchProds = async () => {
    const ProdURL = `https://beta.haromstudio.hu/scriptedprod/wp-json/wp/v2/produkcio?lang=${currentLanguage}&_embed`;
    const res = await Fetcher({ URL: ProdURL, setIsLoading: setIsProdLoading })
    setProds(res)
    setIsLoading(false)
  }
  // const fetchMembers = async () => {
  //   const MemberURL = `https://beta.haromstudio.hu/scriptedprod/wp-json/wp/v2/csapat?lang=${currentLanguage}&_embed`;
  //   const res = await Fetcher({ URL: MemberURL, setIsLoading: setIsMemberLoading })
  //   setMembers(res)
  //   setIsLoading(false)
  // }

  // const scrollHandler = () => {
  //   if (window.scrollY >= 500) {
  //     startPlayer()
  //   }
  // }

  // const startPlayer = () => {
  //   if (paused) {
  //     setPaused(false)
  //   }
  // }

  // useEffect(() => {
  //   window.addEventListener("scroll", scrollHandler)

  //   return () => {
  //     window.removeEventListener("scroll", scrollHandler)
  //   }
  // }, [])

  if (!isLoading && data.acf) {
    return (

      <main className='home'>
        
        <Container>
          <Row className='mt-4'>
            <Col xs={12} lg={12}>
              <div className='block p-5'>
                <div id='hero-text'>
                  <H2 text={currentLanguage === 'hu' ? 'mi vagyunk a' : 'We are'} />
                  <H1 text={companyName} />
                  <div className='mt-4' dangerouslySetInnerHTML={{ __html: data.acf?.slider_szoveg }}></div>
                </div>
              </div>
            </Col>
            {/* <Col xs={12} lg={4} className='m-mt-4'>
              <div className='block block-lead block-team p-4'>
                <div dangerouslySetInnerHTML={{ __html: data.acf?.slider_szoveg }}></div>
                <TeamSlider data={members} />
                <Button variant='primary' className='mt-3' href={currentLanguage === 'hu' ? '/csapat' : '/en/csapat'}>
                  <P text={currentLanguage === 'hu' ? 'csapat' : 'team'} />
                  <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                </Button>
              </div>
            </Col> */}
          </Row>
        </Container>

        <Container>
          <Row className='mt-4'>
            <Col xs={12}>
              <div className='block p-4'>
                <Button variant='secondary' className='' href={currentLanguage === 'hu' ? '/produkciok' : '/en/produkciok'}>
                  <P text={currentLanguage === 'hu' ? 'Produkcióink' : 'Our productions'} />
                  <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                </Button>
                <div className='slider mt-4'>
                  <Slider data={prods} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row className='mt-4'>
            <Col xs={12}>
              <div className='showreel border-16'>
                  {/* <Vimeo video={data.acf.showreel_vimeo_id} loop paused={paused}/> */}
                  <Vimeo video={data.acf.showreel_vimeo_id} loop />
              </div>
            </Col>
          </Row>
        </Container>

      </main>
    )
  } else {
    return (
      <div className='body'></div>
    )
  }
}

export default Home