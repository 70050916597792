import React, { useContext, useEffect, useState }  from 'react'
import { Fetcher } from '../../helpers/api/Fetcher';
import './team.scss'
import ContactCard from '../../components/ContactCard/ContactCard'
import { language } from '../../App';
import { getPrefix } from '../../helpers/prefixHelper';
import H1 from '../../components/Fonts/H1/H1';
import { Col, Container, Row } from 'react-bootstrap';

const Team = () => {
  const { currentLanguage } = useContext(language)
  const prefix = getPrefix(currentLanguage)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])

  useEffect(() => {
    const fetchApi = async () => {
      const URL = `https://beta.haromstudio.hu/scriptedprod/wp-json/wp/v2/csapat?_embed&lang=${currentLanguage}&per_page=30`;
      const res = await Fetcher({ URL, setIsLoading})
      setData(res)

      setIsLoading(false)
    }

    fetchApi()
  }, [currentLanguage])


  if (!isLoading && data) {
    return (
      <main className='csapat'>
          <Container>
            <Row className='mt-4'>
              <Col xs={12}>
                <div className='block block-site-title mt-2'>
                  <H1 text={currentLanguage === 'hu' ? 'csapat': 'team'} />
                </div>
              </Col>
            </Row>
          </Container>
          
          <Container>
            <Row className='leader-wrapper justify-content-md-center'>
              {data.map(member => {
                return (
                  <Col xs={12} lg={5} className={member.acf.kiemelt ? 'leader' : 'member'}>
                    <ContactCard key={member.id} img={member._embedded['wp:featuredmedia'][0].source_url} title={member.title.rendered} desc={member.acf.titulus} url={`${prefix}/csapat/${member.id}`} email={member.acf.email} state={member.acf.kiemelt} />
                  </Col>
                )
              })}
            </Row>
            <Row className='member-wrapper'>
              {data.map(member => {
                return (
                  <Col xs={12} lg={4} className={member.acf.kiemelt ? 'leader' : 'member'}>
                    <ContactCard key={member.id} img={member._embedded['wp:featuredmedia'][0].source_url} title={member.title.rendered} desc={member.acf.titulus} url={`${prefix}/csapat/${member.id}`} email={member.acf.email} state={member.acf.kiemelt} />
                  </Col>
                )
              })}
            </Row>
          </Container>
      </main>
    )
  } else {
    return (
      <main className='body'></main>
    )
  }
}

export default Team