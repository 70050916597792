import React from 'react'
import { Link } from "react-router-dom"
import P from '../Fonts/P/P'
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './contactCard.scss'

const ContactCard = ({img, title, desc, url, state, email}) => {
    return (
        <div className='contact-card'>
            <Link to={url}>
                <div className='poster' style={{backgroundImage: `url(${img})`}}></div>
                <div className='text'> 
                    <Button variant='link' href={url}>
                        <P text={title} />
                        <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                    </Button>
                    <P text={desc} />
                </div>
            </Link>
        </div>
    )
  }

export default ContactCard