import React, { useContext, useEffect, useState } from 'react'
import './footer.scss'
import H5 from '../Fonts/H5/H5'
import P from '../Fonts/P/P'
import { Fetcher } from '../../helpers/api/Fetcher'
import { language } from '../../App'
import { Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Footer = () => {
  const { currentLanguage } = useContext(language)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])

  useEffect(() => {
    const fecthApi = async () => {
      const URL = `https://beta.haromstudio.hu/scriptedprod/wp-json/wp/v2/pages/${currentLanguage==='hu'? 42 : 44}?_embed&acf_format=standard`;
      const res = await Fetcher({ URL, setIsLoading })
      setData(res)

      setIsLoading(false)
    }

    fecthApi()
  }, [currentLanguage])


  return (
    <div className='footer'>
      <Container>
        <Row className='mt-4'>
          <Col xs={12}>
            {!isLoading && data.acf &&
            <div className='block d-flex m-flex-column flex-row justify-content-between align-items-center p-4'>
              <div>
                <H5 text={data.acf.footer_cegnev} bold={true}/>
                <H5 text={data.acf.footer_cim} />
              </div>
              <div>
                <a href={`mailto: ${data.acf.footer_email}`}  target='_blank' rel="noreferrer"><H5 text={data.acf.footer_email} bold={true}/></a>
              </div>
              <div>
                {data.acf.social_oldalak.map(site => {
                  return (
                    <a href={site.link} target='blank' className='ms-lg-2'>
                      <FontAwesomeIcon icon={`fa-brands fa-${site.ikon}`} size='2xl' style={{color: "#000"}} />
                    </a>
                    )
                })}
              </div>
            </div>}
          </Col>  
        </Row>
      </Container>

      <Container>
        <Row className='mt-4 pb-4'>
          <Col xs={12}>
            <div id='copyright' className='block block-black'>
              <P text={currentLanguage === 'hu' ? 'Copyright © 2024 Scripted Productions. Minden jog fenntartva.' : 'Copyright © 2024 Scripted Productions. All rights reserved.'} />
            </div>
          </Col>  
        </Row>
      </Container>
    </div> 
  )
}

export default Footer;