/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import logo from '../../assets/logo.png'
import A from '../Fonts/A/A'
import './header.scss'
import { language } from '../../App'
import { Link } from 'react-router-dom'
import { getPrefix } from '../../helpers/prefixHelper'

const Header = () => {
  const { currentLanguage, changeLanguage  } = useContext(language)
  const [navbarOpened, setNavbarOpened] = useState(false)
  const prefix = getPrefix(currentLanguage)

  useEffect(() => {
    const pages = document.getElementById('pages').childNodes
    pages.forEach(page => {
      page.onclick = () => setNavbarOpened(false)
      
    });
    document.getElementById('logo').onclick = () => setNavbarOpened(false)
  }, [])
  
  return (
    <Container className='pt-4'>
		<Row className='header'>
			<Col xs={12} xl={11}>
				<div className={`block header-menu ${navbarOpened ? 'opened' : 'closed' }`}>
					<div id='logo'>
						<Link to={`${prefix}/`}><img src={logo} alt='logo'/></Link>
					</div>
					<input className="checkbox" type="checkbox" onClick={() => setNavbarOpened(!navbarOpened)} name="" id="" />
					<div className="hamburger-lines">
						<span className="line line1"></span>
						<span className="line line2"></span>
						<span className="line line3"></span>
					</div>
					<div id='pages'>
						<A title={currentLanguage === 'hu' ? "Produkciók" : "Productions"} url={`${prefix}/produkciok`} />
						<A title={currentLanguage === 'hu' ? "saját fejlesztések" : "own developments"} url={`${prefix}/sajat-fejlesztesek`} />
						<A title={currentLanguage === 'hu' ? "csapat" : "team"} url={`${prefix}/csapat`} />
						<A title={currentLanguage === 'hu' ? "podcast" : "podcast"} url={`${prefix}/podcast`} />
						<A title={currentLanguage === 'hu' ? "kapcsolat" : "contact"} url={`${prefix}/kapcsolat`} />
					</div>
				</div>
			</Col>
			<Col xl={1} className='d-none d-xl-block'>
				<div className='block'>
					<a id='lang' className='link' onClick={changeLanguage}>
						{currentLanguage === 'hu' ? "EN" : "HU"}
					</a>
				</div>
			</Col>
		</Row>
    </Container>
  )
}

export default Header;