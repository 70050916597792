import React, { useContext, useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import { Fetcher } from '../../helpers/api/Fetcher';
import { PageTitle } from '../../components/PageTitle/PageTitle'
import Vimeo from '@u-wave/react-vimeo';
import './detailTeam.scss'
import SectionTitle from '../../components/SectionTitle/SectionTitle'
import { language } from '../../App';
import ImageGrid from '../../components/ImageGrid/ImageGrid';
import H1 from '../../components/Fonts/H1/H1';
import H2 from '../../components/Fonts/H2/H2';
import P from '../../components/Fonts/P/P'
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Container, Row } from 'react-bootstrap';

const DetailTeam = () => {
  const { currentLanguage } = useContext(language)
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState({})
  const [isProdLoading, setIsProdLoading] = useState(false)
  const [prods, setProds] = useState()

  useEffect( () => {
      const fetchApi = async () => {
        let URL;
        if (data.translations) {
          URL =`https://beta.haromstudio.hu/scriptedprod/wp-json/wp/v2/csapat/${data.translations[currentLanguage]}?&_embed`;
        } else {
          URL = `https://beta.haromstudio.hu/scriptedprod/wp-json/wp/v2/csapat/${id}?&_embed`;
        }
          const res  = await Fetcher({ URL, setIsLoading})
          await setData(res)
          if (res.acf.produkciok) {
            fetchProds(res)
          }
          setIsLoading(false)

      }

      fetchApi()
      
  }, [currentLanguage])

const fetchProds = async (response) => {
    const ProdURL = `https://beta.haromstudio.hu/scriptedprod/wp-json/wp/v2/produkcio?lang=${currentLanguage}&_embed`;
    const res = await Fetcher({ URL: ProdURL, setIsLoading: setIsProdLoading })
    const filteredArray = res.filter(({id}) => response.acf.produkciok.includes(id))
    setProds(filteredArray)
}

  if (!isLoading && data) {
    return (
      <div className='team-member'>
        <Container>
          <Row className='mt-4'>
            <Col xs={12}>
              <div className='block block-site-title mt-2'>
                <H1 text={data.title.rendered} />
              </div>
            </Col>
          </Row>
        </Container>

        <Container className='about-me'>
          <Row className='mt-4'>
            <Col xs={12}>
              <div className='block p-4 d-flex'>
                <Row>
                  <Col xs={12} lg={4}><img alt='portrait' src={data._embedded["wp:featuredmedia"][0].source_url}/></Col>
                  <Col xs={12} lg={8} className='flex-column'>
                    <div className='description' dangerouslySetInnerHTML={{ __html: data.content.rendered }}></div>
                    <Button variant='link' href={'mailto:'+data.acf.email_cim} className='mt-3'>
                        <P text={currentLanguage === 'hu' ? 'Írj nekem!' : 'Write me!'} />
                        <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>

        {data.acf.videok && 
          <Container>
            <Row className='mt-4'>
              <Col xs={12}>
                <Vimeo video={prods[0].acf.videok[0].vimeo.id} autoplay className='video' height={500}/>
              </Col>
            </Row>
          </Container>
        }
        {prods && 
          <Container>
            <Row className='mt-4'>
              <Col xs={12}>
                <div className='block block-site-title mt-2'>
                  <H2 className='lead' text={currentLanguage === 'hu' ? 'produkcióim' : 'my productions'} />
                </div>
              </Col>
            </Row>
            <Row className=''>
              <Col xs={12}>
                <ImageGrid data={prods}/>
              </Col>
            </Row>
          </Container>
        }
      </div>
    )
  } else {
    return (
      <div className='body'></div>
    )
  }
}

export default DetailTeam