import React, { useEffect, useState, useContext } from 'react'
import { language } from '../../App'
import './ownDeveloped.scss'
import ImageGrid from '../../components/ImageGrid/ImageGrid'
import { Fetcher } from '../../helpers/api/Fetcher'
import H1 from '../../components/Fonts/H1/H1';
import { Col, Container, Row } from 'react-bootstrap';

const OwnDeveloped = () => {
  const { currentLanguage } = useContext(language)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])

  useEffect(() => {
    const fecthApi = async () => {
      const URL = `https://beta.haromstudio.hu/scriptedprod/wp-json/wp/v2/sajat-fejlesztes?_embed&lang=${currentLanguage}`;
      const res = await Fetcher({ URL, setIsLoading })
      setData(res)
      
      setIsLoading(false)
    }
    
    fecthApi()
  }, [currentLanguage])

  if (!isLoading && data) {
  return (
    <main className='sajat-fejlesztesek'>
        <Container>
          <Row className='mt-4'>
            <Col xs={12}>
              <div className='block block-site-title mt-2'>
                <H1 text={currentLanguage === 'hu' ? 'Saját fejlesztések': 'Own developments'} />
              </div>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col xs={12}>
              {!isLoading && <ImageGrid data={data}/>}
            </Col>
          </Row>
        </Container>
    </main>
  )
  } else {
    return (
      <main className='body'></main>
    )
  }
}

export default OwnDeveloped