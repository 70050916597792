import React, {useContext} from 'react'
import { language } from '../../App'
import { Button } from 'react-bootstrap';
import P from '../../components/Fonts/P/P';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './projectCard.scss'

const ProjectCard = ({img, title, desc, url}) => {
  const { currentLanguage } = useContext(language)

    return (
      <div className='project-card'>
        <div className='block d-flex flex-column p-4'>
          <div className='poster' style={{backgroundImage: `url(${img})`}}></div>
          <div className='text'>
              <h3 className='py-3' dangerouslySetInnerHTML={{ __html: title }} />
              <div className='desc' dangerouslySetInnerHTML={{ __html: desc }} />
              <Button variant='primary' className='mt-3' href={url}>
                <P text={currentLanguage === 'hu' ? 'Tovább' : 'Next'} />
                <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
              </Button>
          </div>
        </div>
      </div>
    )
  }

export default ProjectCard