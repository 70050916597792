import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom"
import { Fetcher } from '../../helpers/api/Fetcher';
import './Podcast.scss'
import P from '../../components/Fonts/P/P'
import H1 from '../../components/Fonts/H1/H1'
import { Col, Container, Row } from 'react-bootstrap'
import { language } from '../../App';

const Podcast = () => {
    const { currentLanguage } = useContext(language)
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState({})


    useEffect(() => {
        const fecthApi = async () => {
          const URL = currentLanguage==='hu' ? 'https://beta.haromstudio.hu/scriptedprod/wp-json/wp/v2/pages/366' : 'https://beta.haromstudio.hu/scriptedprod/wp-json/wp/v2/pages/369';
          const res = await Fetcher({ URL, setIsLoading })
          setData(res)
          
          setIsLoading(false)
        }
        
        fecthApi()
      }, [currentLanguage])

    if (!isLoading && data) {
        return (
            <div className='page-content body'>
                <Container>
                    <Row className='mt-4'>
                    <Col xs={12}>
                        <div className='block block-site-title mt-2'>
                            <H1 text={data.title.rendered} />
                        </div>
                    </Col>
                    </Row>
                </Container>

                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className='block'>
                                <div dangerouslySetInnerHTML={{ __html: data.content.rendered }}></div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                
            </div>
        )
    } else {
        return (
            <div className='body'></div>
        )
    }
}

export default Podcast